// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile {
    display: none;
    overflow-x: auto;
    white-space: nowrap;
}

@media (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: flex;
    }
}
`, "",{"version":3,"sources":["webpack://./src/modules/story/components/instory.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".mobile {\n    display: none;\n    overflow-x: auto;\n    white-space: nowrap;\n}\n\n@media (max-width: 768px) {\n    .desktop {\n        display: none;\n    }\n\n    .mobile {\n        display: flex;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
